import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, mergeProps as _mergeProps, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  class: "flex-container",
  style: {"height":"34px"}
}
const _hoisted_2 = { class: "basic-text__14 grey__text" }

import { ref, watch, Ref, shallowRef } from 'vue';
import { cloneDeep, compact, uniqBy, reverse, includes, map, chain } from 'lodash-es';
import { valueMap, statusText, error, setClient, setMode, setPriceType } from './hooks';
import type { SelectOptionType, selectType } from '@/components/FtlSelect/type';

interface selectProps {
  multiple?: boolean; // 是否多选
  placeholder?: string; // 默认提示
  collapseTags?: boolean; // 多闲标签是否折叠
  modelValue: any; // 下拉选择框绑定值
  mapKey: selectType; // 请求方法键值
  client?: string; //  请求固定客户资源
  mode?: string;
  priceType?: string;
  params?: any;
  reverseArray?: boolean;
  transformOptions?: (options: SelectOptionType[]) => SelectOptionType[]; // 新增的可选参数，用于对options进行调整
}


export default /*@__PURE__*/_defineComponent({
  __name: 'FtlSelect',
  props: {
    multiple: { type: Boolean },
    placeholder: { default: '' },
    collapseTags: { type: Boolean },
    modelValue: {},
    mapKey: {},
    client: {},
    mode: {},
    priceType: {},
    params: {},
    reverseArray: { type: Boolean },
    transformOptions: { type: Function, default: (options: SelectOptionType[]) => options }
  },
  emits: ['update:modelValue', 'change'],
  setup(__props: any, { expose: __expose, emit: __emit }) {

const props = __props;
if (props.client) setClient(props.client);
if (props.mode) setMode(props.mode);
if (props.priceType) setPriceType(props.priceType);
const emits = __emit;

const value = shallowRef(props.modelValue ? cloneDeep(props.modelValue) : undefined);
const options: Ref<SelectOptionType[]> = ref([]);

const loadOptions = async () => {
  if (valueMap.has(props.mapKey)) {
    let fetchedOptions = await (valueMap.get(props.mapKey) as any)(props.params);
    if (props.reverseArray) fetchedOptions = reverse(fetchedOptions);
    options.value = props.transformOptions(fetchedOptions); // 使用transformOptions对options进行调整
    filterableOptions.value = cloneDeep(options.value);
  }
};
const emitValue = (val: string | string[]) => {
  emits('update:modelValue', val);
  emits('change', val);
};
const filterableOptions = ref<any[]>([]);
const filterMethod = (val: string) => {
  filterableOptions.value = chain(options.value)
    .filter((option) => includes(option.value, val))
    .uniqBy('value')
    .value();
};
watch(
  () => props.modelValue,
  (val) => {
    if (Array.isArray(val)) {
      value.value = compact(val);
    } else value.value = val;
  }
);

watch(
  () => props.params,
  () => {
    loadOptions();
  },
  { immediate: true }
);

__expose({
  refreshOption: loadOptions,
  setMode
});

return (_ctx: any,_cache: any) => {
  const _component_iconpark_icon = _resolveComponent("iconpark-icon")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!

  return (_openBlock(), _createBlock(_component_el_select, _mergeProps({
    filterable: "",
    clearable: "",
    "allow-create": false,
    placeholder: _ctx.$t('placeholder.select.variable', { str: _ctx.$t(_ctx.placeholder) }),
    modelValue: value.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((value).value = $event)),
    multiple: _ctx.multiple,
    collapseTags: _ctx.collapseTags,
    onChange: emitValue,
    "filter-method": filterMethod
  }, _ctx.$attrs), {
    empty: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("span", _hoisted_2, _toDisplayString(_unref(statusText)), 1),
        _withDirectives(_createVNode(_component_iconpark_icon, {
          name: "redo",
          class: "ml-8 basic__HoverElement",
          onClick: loadOptions
        }, null, 512), [
          [_vShow, _unref(error)]
        ])
      ])
    ]),
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filterableOptions.value, (item) => {
        return (_openBlock(), _createBlock(_component_el_option, {
          key: item.value,
          label: item.label,
          value: item.value
        }, null, 8, ["label", "value"]))
      }), 128))
    ]),
    _: 1
  }, 16, ["placeholder", "modelValue", "multiple", "collapseTags"]))
}
}

})