import http from '@/api';

const baseUrl = '/oms-admin/options';

const getClientOptions = () => {
  return http.get({}, `${baseUrl}/crm/clients`);
};

const getClearanceType = (params: any) => {
  return http.get(params, `${baseUrl}/crm/getClearanceTypes`);
};

const getTransferProducts = (params: any) => http.get(params, `${baseUrl}/psm/getTransferProduct`);

const getTransferProductMappings = () => http.get({}, `${baseUrl}/oms/getTransferProductAlias`);

const getBlackListName = () => http.get({}, `${baseUrl}/psm/getBlackListName`);

const getShipmentSenderAddresses = () => http.get({}, '/oms-shipment/admin/shipmentProduct/getSenderAddressList');

const getTransferProductChannels = (params: any) => http.get(params, `${baseUrl}/psm/channel`);

const getPriceRoutes = (params: any) => http.get(params, `${baseUrl}/drs/findForSelect`);

const getPriceGradeNames = (priceType: string) => http.get({}, `${baseUrl}/crm/getPriceGradeName/${priceType}`);

const getClearanceCountries = () => http.get({}, `${baseUrl}/psm/getCountryCode`);

const getClearanceAirpport = () => http.get({}, `${baseUrl}/crm/getAirPortsAuth`);

export default {
  getClientOptions,
  getClearanceType,
  getTransferProducts,
  getTransferProductMappings,
  getShipmentSenderAddresses,
  getTransferProductChannels,
  getPriceRoutes,
  getPriceGradeNames,
  getBlackListName,
  getClearanceCountries,
  getClearanceAirpport
};
