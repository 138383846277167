import { isArray, join } from 'lodash-es';
import { ElNotification } from 'element-plus';
import { PasteDataType } from '@/utils/type';
import i18n,{globalTranslate} from '@/i18n';

/**
 * 提示⚠️
 * @param message
 */
const warning = (message: string): void => {
  ElNotification.warning({
    title: globalTranslate('action.warning'),
    duration: 2000,
    message: globalTranslate(message),
  });
};
/**
 * 警告❌
 * @param message
 */
const error = (message: string): void => {
  ElNotification.error({
    title: globalTranslate('action.warning'),
    duration: 2000,
    message: globalTranslate(message),
  });
};
/**
 * 成功
 * @param message
 */
const success = (message: string): void => {
  ElNotification.success({
    title: 'Success',
    duration: 2000,
    message: globalTranslate(message),
  });
};

/**
 * 将数据写入剪切板
 * @param input
 */
const pasteData: PasteDataType = async (input) => {
  await navigator.clipboard.writeText(isArray(input) ? join(input, '\n') : input);
  success('action.paste.success');
};

export { pasteData, warning, success, error };
